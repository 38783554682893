import Cookies from 'js-cookie';
import { get, indexOf } from 'lodash';
import { createSelector } from 'reselect';
import { STATEPATH as PARENT_STATEPATH } from './WelcomeConstants';

import { CHECK_REQUEST } from 'base/BaseConstants';
import { isNotCompleteSelector } from 'utils/autoredux';
import Constants from 'utils/constants';
import { ONEID_PROVIDERS } from 'views/welcome/WelcomeConstants';
import { OneIdStatus } from 'components/OneIdProviderWrapper/identityProviders/constants';
import { identityNumberGetter } from './identityNumbers';
import { CheckRequested } from '../../modules';

const STATEPATH = `${PARENT_STATEPATH}`;

const getLocalState = (state) => get(state, STATEPATH);

// Fetching & Error Selectors
export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getIsFetchingSilent = (state) => getLocalState(state).isFetchingSilent;
export const getError = (state) => getLocalState(state).error;

// Response Selectors
export const getFurthestTrack = (state) => getLocalState(state).furthestTrack;
export const getOneIDProvider = (state) =>
    getLocalState(state).enhancedIdentityVerification?.identity_verification_provider || ONEID_PROVIDERS.VOUCHED;
export const getTrackOrder = (state) => getLocalState(state).tracks;
export const getTrackPageOrder = (state) => getLocalState(state).trackPageOrder;
export const getTrackPageLocation = (state) => getLocalState(state).trackPageLocation;
export const getApplicantAccount = (state) => getLocalState(state).applicant_account;
export const getApplicantVerified = (state) => getLocalState(state).applicant_verified;
export const getComplianceRegion = (state) => getLocalState(state).complianceRegion;
export const getCoApplicants = (state) => getLocalState(state).co_applicants;
export const getOnboardingId = () => Cookies.get('onboardingId');
export const getInformation = (state) => getLocalState(state).information;
export const getConvictions = (state) => getLocalState(state).information?.convictions;
export const getIsCosigner = (state) => getLocalState(state).isCosigner;
export const getIsSubmitted = (state) => getLocalState(state).isSubmitted;
export const getIsPrepaid = (state) => getLocalState(state).isPrepaid;
export const getListing = (state) => getLocalState(state).listing;
export const getOnboardingType = () => Cookies.get('onboardingType');
export const getIsOnboardingTypeHR = () => getOnboardingType() === 'HR';
export const getOwnerId = (state) => getLocalState(state).ownerId;
export const getSettings = (state) => getLocalState(state).settings;
export const getApplicant = (state) => getLocalState(state).applicant;
export const getReportStatus = (state) => getApplicant(state)?.report_status;
export const getOrderStatus = (state) => getApplicant(state)?.order_status;
export const getNavigationVisible = (state) => getLocalState(state).navigationVisible;
export const getIsExpired = (state) => getReportStatus(state) === Constants.reportStatus.UNRESPONSIVE;
export const getIsOrderStatusExpired = (state) =>
    [Constants.orderStatus.CANCELLED, Constants.orderStatus.APPLICATION_EXPIRED].includes(getOrderStatus(state));
export const getSkills = (state) => getLocalState(state).skills;
export const getSpecializations = (state) => getLocalState(state).specializations;
export const getDegrees = (state) => getLocalState(state).degrees;
export const getEmployerReferenceSkipReasons = (state) => getLocalState(state).employerReferenceSkipReasons;
export const getConvictionOffenses = (state) => getLocalState(state).convictionOffenses;
export const getS3UploadLink = (state) => getLocalState(state).s3UploadLink;
export const getS3UploadFields = (state) => getLocalState(state).s3UploadFields;
export const getPreparedDocument = (state) => getLocalState(state).preparedDocument;
export const getTransactionReference = (state) => getLocalState(state).transaction_reference;
export const getIdentity = (state) => getLocalState(state).identityVerification;
export const getIdentityQuestions = (state) => getLocalState(state).identityQuestions;
export const getEnhancedIdentity = (state) => getLocalState(state).enhancedIdentityVerification;
export const getInternationalFields = (state) => getLocalState(state).internationalFields;
export const getCustomQuestions = (state) => getLocalState(state).settings.custom_questions;
export const getTeam = (state) => getLocalState(state).listing?.team || getLocalState(state).team;
export const getTeamName = (state) => getLocalState(state)?.team.name;
export const getConsentToMarketing = (state) => getLocalState(state).user?.consentToMarketing;
export const getApplicantLogin = (state) => {
    const settings = getSettings(state);
    const listing = getListing(state);

    if (settings && Object.keys(settings).length === 0) {
        return listing?.team?.settings_config?.applicant_login;
    }

    return settings?.applicant_login;
};

export const getRedirectUrl = (state) => {
    const url =
        getLocalState(state).redirect_url || get(getLocalState(state).enhancedIdentityVerification, ['redirect_url']);
    return url;
};

export const getPositionPropertyLocation = (state) => getLocalState(state).positionPropertyLocation;
export const getSocialMediaDetails = (state) => getLocalState(state).socialMediaDetails;

// Enhanced Identity Track
export const getEnhancedIdentitySubmitted = createSelector(getEnhancedIdentity, (result) => {
    if (!result) return false;
    return result?.status !== OneIdStatus.NONE;
});

export const getEnhancedIdentityPending = createSelector(
    getEnhancedIdentity,
    (result) => result?.status === OneIdStatus.PENDING
);

export const getEnhancedIdentityBypassed = createSelector(
    getEnhancedIdentity,
    ({ status, result }) => status === 'A' && result === 'INCONCLUSIVE'
);

// Information Selectors
export const getPets = createSelector(getInformation, (information) => information.pets_type);
export const getOccupants = createSelector(getInformation, (information) => information.occupants);
export const getEmployers = createSelector(getInformation, (information) => information.employers);
export const getEmployerReferences = createSelector(getInformation, (information) => information.employer_references);
export const getAddresses = createSelector(getInformation, (information) => information.addresses);
export const getCredentials = createSelector(getInformation, (information) => information.credentials);
export const getEducations = createSelector(getInformation, (information) => information.educations);
export const getPersonalReferences = createSelector(getInformation, (information) => information.personal_references);
export const getFullName = createSelector(getInformation, (info) => `${info.first_name} ${info.last_name}`);
export const getAnsweredCustomQuestions = createSelector(getInformation, (info) => info.custom_questions);

export const getSIN = createSelector(getInformation, identityNumberGetter('CA'));
export const getSSN = createSelector(getInformation, identityNumberGetter('US'));
export const getNIN = createSelector(getInformation, identityNumberGetter('GB'));

// Derived Selectors
export const getDefaultFileList = createSelector(getInformation, (information) => {
    if (information.documents) {
        return information.documents.map((document) => ({
            uid: document.id,
            name: document.file_name,
            status: 'done',
            url: document.url,
            type: document.document_type,
            subtype: document.document_subtype,
        }));
    }
    return [];
});

export const getApplicantStatus = createSelector(
    getApplicantVerified,
    getIsSubmitted,
    (applicantVerified, isSubmitted) => {
        if (isSubmitted) return 'submitted';
        if (applicantVerified) return 'verified';
        return 'default';
    }
);

export const getEmail = createSelector(getApplicantAccount, (applicantAccount) => applicantAccount.email);

export const getNextTrack = (state, currentTrack) => {
    const furthestTrack = getFurthestTrack(state);
    const trackOrder = getTrackOrder(state);

    // Forces return to Summary page if furthest track is summary (editing details), but let it pass summary if currently on it
    if (furthestTrack === 'summary' && currentTrack !== 'summary')
        return trackOrder[indexOf(trackOrder, furthestTrack)];

    const numTracks = trackOrder.length;
    const indexOfCurrentTrack = indexOf(trackOrder, currentTrack);
    return indexOfCurrentTrack === numTracks ? numTracks : trackOrder[indexOfCurrentTrack + 1];
};

export const getPrevTrack = (state) => {
    const furthestTrack = getFurthestTrack(state);
    const trackOrder = getTrackOrder(state);
    const indexOfCurrentTrack = indexOf(trackOrder, furthestTrack);
    return indexOfCurrentTrack === 0 ? 0 : trackOrder?.[indexOfCurrentTrack - 1];
};

// Document Upload
export const getCoverLetterReq = (state) => getLocalState(state).settings.cover_letter_req;
export const getGovernmentIdReq = (state) => getLocalState(state).settings.government_id_req;
export const getProofOfIncomeReq = (state) => getLocalState(state).settings.proof_of_income_req;
export const getResumeReq = (state) => getLocalState(state).settings.resume_req;
export const getVisaReq = (state) => getLocalState(state).settings.visa_req;
export const getPassportReq = (state) => getLocalState(state).settings.passport_req;
export const getDocuments = createSelector(getInformation, (information) => information.documents);
export const getProofOfAddressReq = (state) => getLocalState(state).settings.proof_of_address_req;

// MyCRC
export const getTeamIsMyCRC = (state) => getSettings(state)?.my_crc_region;

// Fallback to check for MyCRC in team name
// export const getTeamIsMyCRC = state =>
//     getSettings(state)
//         ?.internal_name?.toLowerCase()
//         .includes('mycrc');

export const getMyCRCPaymentBreakdown = (state) => getLocalState(state).myCRCPaymentBreakdown;
export const getMyCRCLoading = (state) => isNotCompleteSelector(state, 'welcome/GET_STRIPE_PAYMENT_BREAKDOWN');
export const getStripePublicApiKey = (state) => getMyCRCPaymentBreakdown(state).stripe_public_api_key;

// Numio - rip
export const getJumioSupportedIDTypes = (state) => getLocalState(state).supportedIdTypes;

// Specific Teams
export const getTeamIsUK = (state) => getTeam(state).country === 'GB';
export const getTeamIsAUS = (state) => getTeam(state).country === 'AU';

export const getIsUkDbsOrDsCheck = createSelector(getApplicant, (applicant) => CheckRequested.ukCriminal(applicant));

export const getIsInternationalCriminalRecordCheck = createSelector(getApplicant, (applicant) =>
    CheckRequested.internationalCriminal(applicant)
);

export const getIsCanadianCriminalRecordCheck = createSelector(getApplicant, (applicant) =>
    CheckRequested.canadianCriminal(applicant)
);

export const getIsAustraliaACICCheck = createSelector(
    getApplicant,
    (applicant) => CheckRequested.standardACIC(applicant) || CheckRequested.volunteerACIC(applicant)
);

export const getIsUkRtwCheck = createSelector(getApplicant, (applicant) => CheckRequested.ukRightToWork(applicant));

export const getIsUkCheck = createSelector(
    getIsUkDbsOrDsCheck,
    getIsUkRtwCheck,
    (isUkDbsOrDsCheck, isUkRtwCheck) => isUkDbsOrDsCheck || isUkRtwCheck
);

const UK_CHECKS = [
    CHECK_REQUEST.UK_BASIC_DBS_CHECK,
    CHECK_REQUEST.UK_BASIC_DS_CHECK,
    CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK,
];

const applicantExclusivelyHasChecks = (applicant, checks) =>
    Object.values(CHECK_REQUEST)
        .filter((check) => !checks.includes(check))
        .every((check) => !applicant[check]);

export const getIsSolelyUkCheck = createSelector(
    getApplicant,
    getIsUkCheck,
    (applicant, isUkCheck) => isUkCheck && applicantExclusivelyHasChecks(applicant, UK_CHECKS)
);

export const getIsSolelyUkRTWCheck = createSelector(
    getApplicant,
    getIsUkCheck,
    (applicant, isUkCheck) =>
        isUkCheck &&
        applicantExclusivelyHasChecks(applicant, [
            CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK,
            CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION,
        ])
);

const PREPOPULATED_FIELDS = [
    'birth_country',
    'gender',
    'date_of_birth',
    'first_name',
    'middle_name',
    'last_name',
    'address',
];

export const getPrepopulatedFields = createSelector(
    getIsUkDbsOrDsCheck,
    getEnhancedIdentity,
    (isUkDbsOrDsCheck, enhancedIdentity) =>
        isUkDbsOrDsCheck ? PREPOPULATED_FIELDS.filter((field) => enhancedIdentity?.[field]) : []
);
