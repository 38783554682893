// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { Badge } from 'antd';
import { FormattedMessage } from 'react-intl';
import Constants from 'utils/constants';
import { useSelector } from 'react-redux';

import {
    getCheckRequestsToTitles,
    getOptions,
} from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceContent';
import { getPotatoCheckTitles } from '../base/BaseSelectors';

const ServicesBreakdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;

    & section {
        margin: 4px 0;

        & > span {
            color: #ccc !important;
            display: block;
            margin: 0;
            padding: 0;
            font-size: 11px !important;
        }
    }
    & h3 {
        color: white !important;
        margin: 0;
        padding: 0;
        font-size: 14px !important;
    }
    & hr {
        width: 100%;
    }
`;

const bypass = [
    // Not sure if these should be translated yet?
    // Summary objects
    // 'information_result',
    // Statuses
    'Upgrade',
    'UPGRADE',
    'None',
    'NONE',
    // Top level keys
    'report_status',
    'report_result',
    'report_result_label',
    'report_status_label',
    'overall_score',
    'dispute',
    'status',
    'status_label',
    'instant_verify_education_label',
    'instant_verify_credential_label',
    'instant_verify_employment_label',
    'education_verification_label',
    'credential_verification_label',
    'employment_verification_label',
];

const titleMap = (title, options, potatoCheckTitles, isEnhancedCriminal = false) => {
    const potatoCheckTitleMap = potatoCheckTitles?.reduce((acc, check) => {
        acc[check.request] = check.title;
        return acc;
    }, {});

    const titles = {
        motor_vehicle_record_result: (
            <FormattedMessage id="70aa2.ServiceBreakdown.mvr" defaultMessage="Motor Vehicle Records" />
        ),
        identity_verification: (
            <FormattedMessage id="services.identityVerification" defaultMessage="Identity Verification" />
        ),
        enhanced_identity_verification: <FormattedMessage id="70aa2.ServiceBreakdown.id1" defaultMessage="OneID" />,
        manual_identity_verification: (
            <FormattedMessage
                id="70aa2.ServiceBreakdown.manualIdVerification"
                defaultMessage="Manual Identity Verification"
            />
        ),
        us_criminal_record_check_result: (
            <FormattedMessage id="70aa2.ServiceBreakdown.usCrim" defaultMessage="US Criminal Background Check" />
        ),
        information_result: (
            <FormattedMessage id="70aa2.ServiceBreakdown.applicantDetails" defaultMessage="Applicant Details" />
        ),
        rcmp_result: isEnhancedCriminal ? (
            <FormattedMessage
                id="450bc.MicroServicesActive.enhancedCriminalRecordCheck"
                defaultMessage="Enhanced Canadian Criminal Record Check"
            />
        ) : (
            <FormattedMessage
                id="services.canadianCriminalRecordCheck"
                defaultMessage="Canadian Criminal Record Check"
            />
        ),
        reference_result: (
            <FormattedMessage id="70aa2.ServiceBreakdown.referenceCheck" defaultMessage="Reference Check" />
        ),
        risk_result: <FormattedMessage id="70aa2.ServiceBreakdown.Softcheck" defaultMessage="Softcheck™" />,
        vaccination_check_result: (
            <FormattedMessage
                id="70aa2.ServiceBreakdown.vaccinationCheck"
                defaultMessage="COVID-19 Vaccination Status"
            />
        ),
        equifax_result: <FormattedMessage id="70aa2.ServiceBreakdown.equifax" defaultMessage="Equifax Credit Report" />,
        employment_verification: (
            <FormattedMessage id="common.employmentVerification" defaultMessage="Employment Verification" />
        ),
        credential_verification: (
            <FormattedMessage id="services.credentialVerification" defaultMessage="Credential Verification" />
        ),
        education_verification: (
            <FormattedMessage id="common.educationVerification" defaultMessage="Education Verification" />
        ),
        instant_verify_employment: (
            <FormattedMessage
                id="70aa2.ServiceBreakdown.instantEmpVer"
                defaultMessage="Instant Employment Verification"
            />
        ),
        instant_verify_education: (
            <FormattedMessage
                id="70aa2.ServiceBreakdown.instantEduVer"
                defaultMessage="Instant Education Verification"
            />
        ),
        // instant_verify_credential: (
        //     <FormattedMessage
        //         id="70aa2.ServiceBreakdown.instantCredVer"
        //         defaultMessage="Instant Credential Verification"
        //     />
        // ),
        international_criminal_record_check_result: (
            <FormattedMessage
                id="services.intlCriminalRecordCheck"
                defaultMessage="International Criminal Record Check"
            />
        ),
        australian_criminal_intelligence_commission_check_result: (
            <FormattedMessage
                id="3f746.MicroServiceContent.ausAcicCheckTitle"
                defaultMessage="Australian Criminal Intelligence Commission Check"
            />
        ),
        right_to_work: (
            <FormattedMessage
                id="3f746.MicroServiceContent.ausRightToWorkTitle"
                defaultMessage="Australia Right To Work"
            />
        ),
        soquij_result: (
            <FormattedMessage id="70aa2.ServiceBreakdown.soquij" defaultMessage="Quebec Record Check (SOQUIJ)" />
        ),
        social_media_check_result: <FormattedMessage id="services.socialMedia" defaultMessage="Social Media Check" />,
        contact_support: <FormattedMessage id="70aa2.ServiceBreakdown.contact" defaultMessage="Contact Support" />,
        none: <FormattedMessage id="common.none" defaultMessage="None" />,
        no_packages: <FormattedMessage id="70aa2.ServiceBreakdown.NoPackages" defaultMessage="No Packages" />,
        service_missing: <FormattedMessage id="70aa2.ServiceBreakdown.missing" defaultMessage="Check Missing" />,
        ...getCheckRequestsToTitles(options),
        ...potatoCheckTitleMap,
    };
    return titles[title] || titles.service_missing;
};

// Certn Verification example, requires different logic for partial component below
// "certn_verification": {
//     "employment_verification": "None",
//     "credential_verification": "None",
//     "dispute": false,
//     "status": "None",
//     "overall_score": "None",
//     "education_verification": "None"
// },

const mapAdjudicationChecksIntoServices = (checks, options) =>
    checks.map((check) => ({
        title: titleMap(check, options),
        status: Constants.serviceStatus.AWAITING_ACTION,
    }));

const mapServiceLableToTitle = (serviceLabels) => {
    const AdjudicationCheckScoreLabelToTitleMap = {
        // 'check label enum from CheckScore model (api)': 'key from title map above',
        EQUIFAX: 'equifax_result',
        RCMP: 'rcmp_result',
        VACCINATION: 'vaccination_check_result',
    };
    return serviceLabels.map((label) => AdjudicationCheckScoreLabelToTitleMap[label]);
};

const Status = ({ reportSummary = {}, application = {} }) => {
    const removedEntries = (item) => !bypass.includes(item);
    const servicesWaitingOnCandidate = [];
    const servicesWaitingOnReference = [];
    const servicesUnresponsive = [];
    const servicesReturned = [];
    const servicesInProgress = [];
    const servicesWaitingOnAdjudicatorTitles = mapServiceLableToTitle(
        application?.application?.applicant?.services_undergoing_adjudication ?? []
    );
    const options = useSelector(getOptions);
    const servicesWaitingOnAdjudicator = mapAdjudicationChecksIntoServices(servicesWaitingOnAdjudicatorTitles, options);
    const isEnhancedCriminal = application.request_enhanced_criminal_record_check;

    const services = Object.entries(reportSummary)
        .filter(
            ([serviceName, serviceResultModel]) =>
                // service name: str, serviceResultModel: dict
                serviceResultModel &&
                removedEntries(serviceName) &&
                removedEntries(serviceResultModel.status) &&
                !servicesWaitingOnAdjudicatorTitles.includes(serviceName)
        )
        .flatMap(([serviceName, serviceResultModel]) => {
            if (['certn_verification', 'instant_verify_result'].includes(serviceName)) {
                // certn_verification && instant verify are structured differently, 3 objects/divs required for display
                const mappedStatus = Object.entries(serviceResultModel)
                    .filter((i) => removedEntries(i[0]) && removedEntries(i[1]))
                    .map((i) => ({
                        title: titleMap(i[0]),
                        status: i[1],
                    })); // there is no .status on certn_verification results
                return mappedStatus;
            }
            if (
                serviceName === 'manual_identity_verification' &&
                serviceResultModel === Constants.serviceStatus.ANALYZING
            ) {
                return {
                    title: titleMap(serviceName),
                    status: Constants.serviceStatus.IN_PROGRESS,
                };
            }

            return {
                title: titleMap(serviceName, options, undefined, isEnhancedCriminal),
                status: serviceResultModel.status,
            };
        });

    services.forEach((service) => {
        // Waiting on candidate
        if (
            service.status === Constants.serviceStatus.PENDING ||
            service.status === Constants.serviceStatus.IN_PROGRESS ||
            service.status === Constants.verificationStatus.VERIFICATION_PENDING
        ) {
            return servicesWaitingOnCandidate.push(service);
        }

        // Waiting on reference
        if (service.status === Constants.serviceStatus.AWAITING_ACTION) {
            return servicesWaitingOnReference.push(service);
        }

        // Unresponsive
        if (service.status === Constants.serviceStatus.UNRESPONSIVE) {
            return servicesUnresponsive.push(service);
        }

        // In progress
        if (
            service.status === Constants.serviceStatus.ANALYZING ||
            service.status === Constants.serviceStatus.PARTIAL ||
            service.status === Constants.serviceStatus.ACTION_REQUIRED
        ) {
            return servicesInProgress.push(service);
        }

        // Default - returned
        return servicesReturned.push(service);
    });
    const checksRemaining =
        servicesWaitingOnCandidate.length + servicesInProgress.length + servicesWaitingOnAdjudicator.length;

    return (
        <ServicesBreakdownContainer>
            <h3>
                <FormattedMessage
                    id="70aa2.ServiceBreakdown.checksRemaining"
                    defaultMessage="{checksRemaining, plural, =0 {Complete} one {# check remaining} other {# checks remaining}}"
                    values={{ checksRemaining }}
                />
            </h3>
            {services.length > 0 && <hr />}
            <StatusDisplay
                returned
                services={servicesWaitingOnCandidate}
                display={<FormattedMessage id="status.waitingOnCandidate" defaultMessage="Waiting on candidate" />}
            />
            {servicesWaitingOnAdjudicator.length > 0 && (
                <StatusDisplay
                    returned
                    services={servicesWaitingOnAdjudicator}
                    display={
                        <FormattedMessage id="status.waitingOnAdjudicator" defaultMessage="Waiting on adjudicator" />
                    }
                />
            )}
            <StatusDisplay
                returned
                services={servicesInProgress}
                display={<FormattedMessage id="status.inProgress" defaultMessage="In progress" />}
            />

            <StatusDisplay
                returned
                services={servicesReturned}
                display={<FormattedMessage id="status.returned" defaultMessage="Returned" />}
            />
            <StatusDisplay
                returned
                services={servicesWaitingOnReference}
                display={<FormattedMessage id="status.waitingOnReference" defaultMessage="Waiting on reference" />}
            />
            <StatusDisplay
                returned
                services={servicesUnresponsive}
                display={<FormattedMessage id="status.unresponsive" defaultMessage="Unresponsive" />}
            />
        </ServicesBreakdownContainer>
    );
};

const StatusDisplay = ({ services, display, returned }) =>
    services.length > 0 ? (
        <section>
            <span>{display}:</span>
            {services.map((service) => (
                <h3 key={service} returned={returned}>
                    • {service.title}
                </h3>
            ))}
        </section>
    ) : null;

const Package = ({ data = {}, potatoCheckTitles }) => {
    const serviceCreate = (item, title = titleMap(item, undefined, potatoCheckTitles)) =>
        item && title ? (
            <div key={title}>
                <Badge style={{ top: '-1px' }} dot status="success" />
                {title}
            </div>
        ) : null;

    const services = Object.entries(data)
        .filter((i) => i[0].startsWith('request_') && i[1])
        .map((item) => serviceCreate(item[0]));

    return (
        <ServicesBreakdownContainer>
            {services.length === 0 ? serviceCreate('no_packages', 'none') : services}
        </ServicesBreakdownContainer>
    );
};

const ServiceBreakdown = ({ packages, status, data, ...rest }) => {
    const potatoCheckTitles = useSelector(getPotatoCheckTitles);
    if (packages && data) return <Package data={data} {...rest} potatoCheckTitles={potatoCheckTitles} />;
    if (status && data) return <Status reportSummary={data} {...rest} />;
};

export default ServiceBreakdown;
